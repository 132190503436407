import './styles.scss'

import { Link } from 'react-router-dom'

import Layout from '../../layout'

type HomeProps = {
  title?: string
}

const machineName = window.location.hostname
  .replace('.com', '')
  .replace('.net', '')
  .replace('.org', '')
  .replace('www.', '')

const Home: React.FunctionComponent<HomeProps> = () => (
  <Layout
    documentTitle={machineName}
    className="home flex h-screen flex-col items-end justify-center p-10"
  >
    <h2 className="text-lg font-black tracking-wide">{machineName}</h2>
    <div className="text-right">
      <Link className="link text-right" to="/privacy">
        Privacy Policy
      </Link>
    </div>
  </Layout>
)

export default Home
