import './styles.scss'

import { Link } from 'react-router-dom'

import Layout from '../../layout'

const Privacy = () => (
  <Layout documentTitle="Privacy Policy" className="privacy">
    <div className="min-w-5xl container mx-auto max-w-6xl">
      <div className="p-30">
        <h1 className="pb-30">GammaPix&trade; Privacy Policy</h1>
        <p>
          Image Insight Inc. (&quot;I3&quot;) is committed to respecting your
          privacy. We recognize that you would wish any information you provide
          through our GammaPix mobile security applications
          (&quot;GammaPix&quot;), or which GammaPix collects via sensor
          readings, to be protected against unauthorized use and disclosure. We
          have established this Privacy Policy to explain how we manage the
          information we receive from you to try to fulfill those wishes.
        </p>
        <p>
          We will endeavor to respect your privacy in accordance with the
          Privacy Policy below, and welcome any questions or comments you may
          have. Please contact us at:{' '}
          <a
            href="mailto:privacy@imageinsightinc.com?Subject=Privacy%20Policy"
            className="link"
          >
            privacy@imageinsightinc.com
          </a>{' '}
          with any queries or concerns.
        </p>
        <h2>1. Overview</h2>
        <p>
          This Privacy Policy describes the information I3 collects through
          GammaPix, how that information is used, and the third parties with
          whom we share it. This Privacy Policy applies only to information
          collected via GammaPix and not to information you provide by other
          means, including for example, through the I3 website, the dashboard
          associated with GammaPix Pro by email, social media, or on paper.
          Additionally, this Privacy Policy does not apply to any third-party
          websites, including those you reach through links we have provided in
          GammaPix. We encourage you to request and review the privacy policies
          of any third party prior to disclosing information to such party.
        </p>
        <p>
          &quot;Personally Identifiable Information&quot; is information that
          identifies you or another individual, or can be used on its own or
          with other information to identify, contact, or locate you or the
          other individual, including, but not limited to, contact information
          such as your name, address, telephone number and email address.
        </p>
        <h2>2. NATURE OF THE INFORMATION WE COLLECT VIA GAMMAPIX</h2>
        <p>
          The information collected by GammaPix is encrypted for transmission
          and stored on secure, access-restricted servers. I3 regularly monitors
          these servers and conducts regular security testing.
        </p>
        <ol type="a">
          <li>
            Information You Choose to Provide to I3
            <p>
              GammaPix is designed to collect sensor data from your device,
              including ionizing radiation levels, location data and
              environmental data such as vibration, electromagnetic fields,
              temperature and other environmental information. GammaPix does NOT
              store audio, video or pictures from your device.
            </p>
            <p>
              I3 may collect, store, and use any information you choose to
              provide, including your Personally Identifiable Information. If
              you are using GammaPix at the request of an employer or other
              third party, that third party in the course of assigning you a
              license to the app may have provided your name or another
              identifier qualifying as Personally Identifiable Information.
            </p>
            <p>
              I3 may also collect information about your use of GammaPix,
              including device identifier information, the device operating
              system and firmware; mobile phone carrier; geographical data such
              as country, region and/or city; when and where you activate
              GammaPix and/or make measurements; the manner in which you use
              GammaPix; the frequency with which you use GammaPix to make and to
              communicate detector readings and to receive alerts or commands
              to/from I3 or affiliate servers, and information you provide about
              a location. For example, such information is collected about you
              when you: (a) register as a user of GammaPix or as an
              administrator of a GammaPix Pro group; (b) use any data
              transmission functions available through GammaPix; or (c) enter
              information into GammaPix. We may also use server log analysis
              tools to gather and analyze information regarding readings and
              errors experienced by users. This information may be collected via
              several technologies, including cookies. The information you
              provide us belongs to I3 and its use by us is governed by this
              Privacy Policy.
            </p>
          </li>
          <li>
            Information Collected Automatically by Third Parties
            <p>
              I3 utilizes third-party bug- and performance-tracking tools in
              GammaPix with privacy governed by the privace policies of those
              parties. Please be aware that using ANY app on a mobile device
              inherently creates data that is collected by parties ranging from
              the device manufacturer (for example, Apple or Samsung) to the
              carriers that your device uses. These parties may automatically
              collect certain data about how you use GammaPix, including but not
              limited to: (i) device properties, such as the device identifier;
              (ii) the device operating system and firmware; (iii) mobile phone
              carrier; (iv) geographical data such as country, region and/or
              city; (v) when and where you activate GammaPix and/or make
              measurements; (vi) the manner in which you use GammaPix; (vii) the
              frequency with which you use GammaPix to make and to communicate
              detector readings and to receive alerts or commands to/from I3 or
              affiliate servers; and (viii) other similar data. This information
              may be collected via several technologies, including cookies.
            </p>
          </li>
        </ol>
        <h2>3. USE OF PERSONALLY IDENTIFIABLE INFORMATION</h2>
        <p>
          I3 may use the Personally Identifiable Information you provide,
          separately or in combination with other information I3 collects from
          you and other users, to support the purposes of the GammaPix app,
          enhance the value of the app to you and others, and improve the app,
          including:
        </p>
        <ul>
          <li>
            <p>
              To map the ionizing radiation levels and other sensor data in a
              given area and throughout the world, identify changes in radiation
              levels, and report information about radiation levels to you,
              other users and third parties.
            </p>
          </li>
          <li>
            <p>To communicate with you about I3 and your use of GammaPix.</p>
          </li>
          <li>
            <p>
              To process the data, alerts, command and control messages and
              other communications you or I3 and affiliate servers make through
              GammaPix and to provide services you have requested.
            </p>
          </li>
          <li>
            <p>
              To alert you to upgrades, service updates, special offers and
              other information about I3 and the programs we offer.
            </p>
          </li>
          <li>
            <p>
              To characterize the imaging and other characteristics of your
              device&apos;s cameras and other sensors in order to improve the
              functionality and reliability of GammaPix on your device.
            </p>
          </li>
          <li>
            <p>
              To conduct research to improve I3 products, conduct scientific and
              engineering investigations, to support or conduct public safety
              activities, to monitor locations for enhanced ionizing radiation
              or other threats, to better characterize the normal and anomalous
              detector readings at locations.
            </p>
          </li>
        </ul>
        <p>
          We may also use the information you provide to operate and maintain
          GammaPix and to improve our effectiveness, content, services and
          communications with you, to enforce the Terms of Use accompanying
          GammaPix, and to detect and protect against error, fraud, and any
          other unauthorized or illegal activities.
        </p>
        <p>
          If you are using GammaPix from a country outside the United States,
          you acknowledge and agree that in using GammaPix, you may transfer
          Personally Identifiable Information about yourself to the United
          States; that the data protection laws of the United States may not be
          as comprehensive as those in your own country; and that transferring
          this personal information occurs with your consent. Personally
          Identifiable Information collected through GammaPix may be stored and
          processed in the United States, under the laws of the United States.
        </p>
        <h2>4. DISCLOSURE OF YOUR PERSONALLY IDENTIFIABLE INFORMATION</h2>
        <p>
          We may disclose your Personally Identifiable Information and other
          information to third parties, as follows:
        </p>
        <ul>
          <li>
            <p>
              To our affiliates, to our advisors and to third-party partners
              whom we engage to provide products and services on our behalf, and
              who are subject to confidentiality agreements and/or privacy
              policies, including, but not limited to, the servicers of
              GammaPix&apos;s back-end administrative platform and providers of
              security measures related to GammaPix, as well as other website
              services, newsletter and email delivery, and website analytics
              services.
            </p>
          </li>
          <li>
            <p>
              To your employer or the other person or entity providing you the
              license to use GammaPix.
            </p>
          </li>
          <li>
            <p>
              To a successor organization of I3, if I3 is involved in a
              transaction such as a merger, winding down, or transfer of all or
              some of I3&apos;s assets to another entity for whatever reason.
            </p>
          </li>
          <li>
            <p>
              In response to a subpoena or court order, or to establish or
              exercise our legal rights, to defend against claims, or in
              response to a request from a law enforcement, public safety or
              other government agency as I3 determines appropriate to protect
              the safety or security of the public or of users of GammaPix or I3
              programs, products and services.
            </p>
          </li>
        </ul>
        <p>
          Except as described above, I3 DOES NOT RENT, SELL OR OTHERWISE PROVIDE
          YOUR PERSONALLY IDENTIFIABLE INFORMATION TO OTHER GAMMAPIX USERS OR
          OTHER THIRD PARTIES WITHOUT YOUR CONSENT.
        </p>
        <h2>5. PROTECTION OF YOUR INFORMATION</h2>
        <p>
          I3 follows generally accepted security practices and procedures to
          protect the Personally Identifiable Information you provide from
          unauthorized access, use and disclosure. We require our authorized
          service providers to implement and maintain such security practices
          and procedures as well. Nevertheless, be aware that Internet
          transmissions are never completely private or secure.
        </p>
        <p>GammaPix includes the following features to safeguard your data:</p>
        <ul>
          <li>
            <p>
              Extensive use of anonymizing ID codes rather than names, phone
              numbers, or email addresses.
            </p>
          </li>
          <li>
            <p>Multiple character complex passwords.</p>
          </li>
          <li>
            <p>
              Advanced network topologies and routing requirements with strict
              access controls.
            </p>
          </li>
          <li>
            <p>Use of anti-malware software.</p>
          </li>
          <li>
            <p>
              Use of certified ethical hackers to perform extensive penetration
              testing of GammaPix software and I3 servers.
            </p>
          </li>
          <li>
            <p>
              Encryption of all information on your mobile device and in
              transit; use of military-grade encryption for position
              information; and storage of the information on secure servers.
            </p>
          </li>
        </ul>
        <h2>6. RETENTION OF YOUR PERSONALLY IDENTIFIABLE INFORMATION</h2>
        <p>
          Your Personally Identifiable Information will be stored and maintained
          by I3 for as long as we believe it is necessary or appropriate to (a)
          carry out the purpose(s) for which such information was collected, or
          (b) to comply with applicable laws, contracts or other rules or
          regulations.
          <b>
            Since I3 does not collect enough information to connect individuals
            to devices, I3 currently has no practical means of deleting
            information concerning individual users.
          </b>
        </p>
        <p>We safeguard your data in several ways:</p>
        <ul>
          <li>
            On I3&apos;s Database Servers
            <ul>
              <li>
                <p>
                  GammaPix uses secure, redundant, dedicated servers.
                  GammaPix&apos;s servers use sophisticated network, routing,
                  access, and encryption technologies. Your data will be
                  accessible exclusively to I3 and authenticated users via
                  restricted Application Protocol Interfaces so that
                  unauthorized personnel do not access protected data, and that
                  such data is not shared with any other organization, app, or
                  service provider except as specified above.
                </p>
              </li>
              <li>
                <p>
                  All Personally Identifiable Information is anonymized to the
                  extent reasonably possible, depending upon the services that
                  are required.
                </p>
              </li>
              <li>
                <p>
                  Currently no contact data is collected other than that
                  required to support remote detector operation for relevant
                  software configurations. The keys to carry out that operation
                  will be deleted from I3&apos;s database after that service is
                  no longer licensed. If other contact information is collected
                  in the future, we will amend this privacy policy and notify
                  users of the change.
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <h2>7. CHILDREN&apos;S PRIVACY</h2>
        <p>
          GammaPix is intended for adults. Accordingly, we do not knowingly
          collect information from children under the age of 13.
        </p>
        <h2>8. REVISIONS TO THIS PRIVACY POLICY</h2>
        <p>
          I3 may modify this Privacy Policy at any time. When we make such
          modifications we will post them on our website. Any modifications will
          be effective on the date that appears on the modified Policy. Each
          time you use GammaPix, we recommend that you check the Effective Date
          of this Privacy Policy and review any changes made since the last time
          you visited the policy. Your continued use of GammaPix following
          modification of this Policy will be conclusively deemed to signify
          your acceptance of the modification.
        </p>
        <p>
          <b>EFFECTIVE DATE: Dec. 1, 2022</b>
        </p>
      </div>
      <div className="my-20 text-center">
        <Link className="link" to="/">
          home
        </Link>
      </div>
    </div>
  </Layout>
)

export default Privacy
