/* eslint-disable @typescript-eslint/no-unused-vars */
import './styles.scss'

import useDocumentTitle from '@hooks/useDocumentTitle'
import { ScrollToTopOnNav } from '@utils/ScrollToTopOnNav.jsx'
import React, { ReactNode } from 'react'
import GithubCorner from 'react-github-corner'

import Footer from '@components/footer'

interface LayoutProps {
  documentTitle?: string
  className?: string
  children: ReactNode
}

export const Layout: React.FunctionComponent<LayoutProps> = ({
  documentTitle,
  className,
  children,
}) => {
  const title = documentTitle
    ? `${documentTitle} | Image Insight Inc.`
    : 'Image Insight Inc.'
  const appClass = className ? `app ${className}` : 'app'
  useDocumentTitle(title)
  return (
    <ScrollToTopOnNav>
      <div className={`${appClass}`}>
        <main>{children}</main>
        <Footer />
      </div>
    </ScrollToTopOnNav>
  )
}
export default Layout
