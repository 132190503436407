import './styles.scss'

import { Link } from 'react-router-dom'

import Layout from '../../layout'

const NotFound = () => (
  <Layout
    documentTitle="404: Page Not Found"
    className="home flex h-screen flex-col items-end justify-center p-10"
  >
    <h2 className="text-lg font-black tracking-wide">Page Not Found</h2>
    <div className="text-right">
      <Link className="link text-right" to="/">
        Return to homepage
      </Link>
    </div>
  </Layout>
)

export default NotFound
